import React from 'react';
import './App.css';
import { Main, OurStory, FAQ, Photos, Registry, DayOf, } from './pages';
// import { RSVP, Admin } from './pages';

import {
  BrowserRouter as Router,
  Switch,
  Route,
} from "react-router-dom";

export default function App () {
  return (
    <Router>
      <Switch>
        <Route path="/our-story">
          <OurStory />
        </Route>
        <Route path="/day-of">
          <DayOf />
        </Route>
        <Route path="/faq">
          <FAQ />
        </Route>
        <Route path="/photos">
          <Photos />
        </Route>
        <Route path="/registry">
          <Registry />
        </Route>
        {/* <Route path="/rsvp">
          <RSVP />
        </Route> */}
        {/* <Route path="/admin">
          <Admin />
        </Route> */}
        <Route path="/">
          <Main />
        </Route>
      </Switch>
    </Router>
  );
};
