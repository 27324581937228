import React from "react";
import { Header, Footer, Link } from '.';

type PageProps = {
  pageName: string,
  children: React.ReactNode,
};

export default function Page(props: PageProps) {
  return (
    <div className="flex flex-col min-h-screen">
      <Header className="flex-initial" pageName={props.pageName} />
      <div className="flex-grow container md mx-auto text-gray-700 p-16 font-serif">{props.children}</div>
      <Footer className="flex-initial px-16">
        <h1 className="text-white text-4xl">J&H</h1>
        <p className="text-center">Made with <span role="img" aria-label="heart">❤️</span> by <Link href="http://johnward.me">John</Link></p>
      </Footer>
    </div>
  );
}