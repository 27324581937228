import React from "react";
import { Page, Link } from '../components';

export default function Registry() {
  return (
    <Page pageName="Registry">
      <div className="prose mx-auto">
        <p>We have a registry at Crate & Barrel: <Link href="https://www.crateandbarrel.com/gift-registry/hannah-kim-and-john-ward/r6183408">here</Link></p>
        <p>Alternatively, if you'd like to make any cash gifts, you can address a check to either of us: John Ward or Hannah Kim.</p>
        <p>Thank you!</p>
      </div>
    </Page>
  );
};