import React, { useReducer, useEffect } from "react";
import { Page } from '../components';

//@ts-ignore
import Gallery from 'react-grid-gallery';

const imgPath = `${window.location.protocol}//${window.location.host}/photos`;
const thumbsPath = `${imgPath}/thumbs`;

const loadImg = (imgSrc:string) => {
  return new Promise((resolve, reject) => {
    const thumbSrc = `${thumbsPath}/${imgSrc}`;
    const img = new Image();
    img.onload = () => {
      const [width, height] = [img.width, img.height];
      resolve({src: `${imgPath}/${imgSrc}`, thumbnail: thumbSrc, thumbnailWidth: width, thumbnailHeight: height});
    };
    img.src = thumbSrc;
  });
};


export default function Photos() {
  const [loadedImages, setLoadedImages] = useReducer(
    //@ts-ignore
    (loadedImages, newLoadedImage) => ([...loadedImages, newLoadedImage]),
    []
  );

  useEffect(() => {
    [
      'DSC_1645.jpg',
      'DSC_1654.jpg',
      'DSC_1672.jpg',
      'DSC_1715.jpg',
      'DSC_1721.jpg',
      'DSC_1802.jpg',
      //'DSC_1871.jpg',
      //'DSC_1924.jpg',
      //'DSC_1952.jpg',
      'IMG_0210.jpg',
      'IMG_0242.jpg',
      'IMG_0249.jpg',
      'IMG_0252.jpg',
      'IMG_0253.jpg',
      'IMG_0255.jpg',
      'IMG_1446.jpg',
      'IMG_1447.jpg',
      'IMG_1448.jpg',
      'IMG_1449.jpg',
      'IMG_1450.jpg',
      'IMG_1451.jpg',
      'IMG_1452.jpg',
      'IMG_1513.jpg',
      'IMG_2134.jpg',
      'IMG_5438.jpg',
      'IMG_5601.jpg',
      'IMG_5938.jpg',
      'IMG_6794.jpg',
      'IMG_6804.jpg',
      'IMG_7387.jpg',
      'IMG_8390.jpg',
      'IMG_8875.jpg',
      'IMG_9069.jpg',
      'IMG_9149.jpg',
      'IMG_9333.jpg',
      'IMG_9735.jpg',
      'IMG_9844.jpg',
      'DR3A3795.jpg',
      'DR3A3867.jpg',
      'DR3A3957.jpg',
      'DR3A4004.jpg',
      '30C2CA63-78DD-41B0-8053-4FA5332AC538.jpg',
      'B0CD5FC2-A0BE-4047-AB2B-3022642470B2.jpg',
      'FFC8354F-5C09-4488-B204-1DB0898F1622.jpg',
      'IMG_0373.jpg',
      'IMG_0867.jpg',
      'IMG_0954.jpg',
      'IMG_2741.jpg',
      'IMG_3140.jpg',
      'IMG_3251.jpg',
      'IMG_6861.jpg',
      'IMG_8100.jpg',
      'IMG_3784.jpg',
      'IMG_8868.jpg',
      'IMG_9789.jpg',
      'IMG_1209.jpg',
    ].forEach((imgSrc) => {
      loadImg(imgSrc).then((imgDetails) => {
        //@ts-ignore
        setLoadedImages(imgDetails);
      });
    });
  }, []);

  return (
    <Page pageName="Photos">
      <Gallery images={Object.values(loadedImages)}/>
    </Page>
  );
};