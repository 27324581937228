import React from "react";
import { Page } from '../components';
// import CustomizedTimeline from '../components/Timeline';

export default function OurStory() {
  return (
    <Page pageName="Our Story">
      <div className="prose mx-auto">
        <h2>How did you meet?</h2>
        <p>In May 2018, John messaged Hannah on OkCupid that Hannah did not see until 5 months later. Fast forward to October 2018, Hannah saw the message and replied with a hi, not expecting an answer back. To her surprise, John answered!</p>
        <h2>What was the first date like?</h2>
        <p>Their first date was at John's Pizzeria in the City where they had a pie of Hawaiian (yes, pineapples on pizza). They then headed inside to the Public Library because it was raining and looked at shelves of books, old oil paintings, and old art. Nobody knows why Hannah stuck around after looking at art on the first date, but Hannah and John kept talking the next day.</p>
        <p>They revisit John's Pizzeria for the Hawaiian pizza every year in October. But not the New York Public Library.</p>
        <h2>Tell us more about the proposal!</h2>
        <h3>Hannah's Point of View:</h3>
        <p>I knew the proposal was going to happen sooner or later because the wedding venue had already been booked and I wanted the proposal by the end of January. I also work every other weekend, so John didn't have many available days. On New Years Eve, he took me out to a nice restaurant in Hoboken and kept wanting to walk around the waterfront (we like waterfronts), but he didn't look nervous at all. So I knew the proposal wasn't happening then, although I was low-key looking forward to it.</p>
        <p>On my next weekend off, John told me he's taking me somewhere but didn't disclose the location. He had planned a picnic at the State Line Lookout in Alpine. It was cute, but also 30 degrees outside. After the wind blowing away our coffee, we finished our food in the car and started hiking. I was thinking, maybe he'll propose in the woods, so I frequently looked around to spot for any suspicious looking photographers. The proposal didn't happen, but nevertheless we still had a good time.</p>
        <p>Then Saturday, January 23rd, came around, and I knew it either had to happen that day or the next day, since this was my last weekend off in January. I was suspicious of the 23rd because of the 1-23-21 palindrome. John told me he needed to take me somewhere but we had to be there by 3:30-4. By the way he was acting, I knew the proposal was going to happen but just didn't know where. While he was driving us to the destination, he received a text from a guy named Steven, and while I couldn't see the text itself, I knew it had to be the photographer. We arrived at Liberty State Park and noticed there was a good number of photoshoots happening. I asked John, "Which one of these photographers is Steven?" Shortly after, he got down on one knee and popped the question! And there was Steven, snapping photos of us.</p>
        <h3>John's Point of View:</h3>
        <p>I had been talking about wanting to go on a ski trip for many months prior, before we had started planning a wedding. So, I thought that trip would be the perfect cover for a surprise proposal. When Hannah pressed at times, I vaguely said 'the plan has to happen in 2021. Nothing I can do about it.' And that was true, at the time.</p>
        <p>In October, I compiled a list of rings I was interested in and settled on one. Then when I went to order, the website was down, and it wouldn't let me place the order. Several frustrated phone calls later, I was never able to get the order to go through, and I quickly switched to another jeweler with a longer shipping time. The process of waiting for it was agonizing, while I hoped nothing would be wrong with it where I'd need to get a modification, risking throwing off all my plans.</p>
        <p>Another tight deadline for the ring was soon approaching: I wanted to be able to bring it to Virginia with us on our Christmas trip to show to my parents so they could get a sneak peak. I also wanted Hannah to meet my grandmother before I proposed, so any time before Christmas was out of the question. Around this time, I ruled out ski trip as a possibility, since traveling to other states was facing some restrictions due to COVID, and if we planned to go somewhere and it suddenly got added to New Jersey's quarantine list, my whole plan could be ruined. So, I started thinking local instead, and since I'd built up this expectation of "not until 2021" I thought it would be fun to subvert that and propose on New Years Eve if I could.</p>
        <p>I started looking around for places in the area that were significant to us: Stone Street in Manhattan, parks and cafes in Fort Lee and Edgewater we used to frequent, DUMBO in Brooklyn, ... I contacted several places to try to rent out an indoor space, I looked into renting an Airbnb, and I looked at renting out a rooftop in the city, as I worried about firstly having a private space where I'd be sure nothing would get in our way and we could comfortably go maskless, and secondly what we would do in the event of bad weather. I eventually thought Liberty State Park could be the answer, and when I started talking to photographers, the photographer I ended up hiring assured me we'd have plenty of backup locations no matter what happened, since it is a large park.</p>
        <p>The ring came on December 23rd, right before I was heading over to Jersey to meet up with Hannah to drive down to Virginia. I hid it in my bag, successfully showed it off, and Hannah was none the wiser. I told her I planned a New Years Eve date, so she knew something was happening. But, she had also speculated many times by this point that the proposal would be happening on January 23rd since she liked the palindrome number: 1-23-21, the same numbers backwards and forwards. So, I delayed my plans to then. But, she was so sure it was going to be then that I felt like I needed to throw her off multiple times before the day came.</p>
        <p>I ended up planning a "month of dates," starting with New Years Eve, where every weekend she was off I would plan something that could reasonably be the proposal: a fancy dinner and walk around a park, a picnic and hike, and then finally the actual proposal date, with the day after the proposal marked on our calendar as "Date #4" to throw her off even more. Finally, the day came, we played Mario Party on the Nintendo Switch, and then it was time for this sunset proposal. So I got us in the car, shared my location with the photographer through iMessage, and drove us down to Jersey City. During the drive, though, a text from the photographer popped up on the car's screen! Luckily it just showed his name and not the message, but Hannah was already super suspicious at this point. And I was nervous what the text might say.</p>
        <p>We arrived, and I checked my phone, not able to keep too much of a secret, and he said he hadn't received my location share! I panicked and shared it again and told him we're only a minute away from the spot but I would stall to make sure he had time to get into position. So, I took Hannah around the long way, admiring the water, and when I got a text saying he was in position, I brought her over to the spot. I reminisced some about our relationship, told her how much I love her and how she makes me a better man, and got down on one knee. I think we were both a bit numb after all that, from the extremely cold air but also from all the excitement and smiling.</p>
      </div>
      {/* <CustomizedTimeline /> */}
    </Page>
  );
};