import React from 'react';
import { NavLink as RouterLink } from "react-router-dom";

type NavProps = {
  className?: string,
};

export default function Nav({className}: NavProps) {
  return (
    <nav className={className + " py-4 font-serif"}>
      <ul className="flex flex-wrap w-screen text-white text-lg">
        <li className="flex-grow px-4 text-center no-wrap" key="story">
          <RouterLink to="/our-story" exact activeStyle={{fontWeight: 'bold', textDecoration: "underline"}}>Our Story</RouterLink>
        </li>
        <li className="flex-grow px-4 text-center no-wrap" key="dayOf">
          <RouterLink to="/day-of" exact activeStyle={{fontWeight: 'bold', textDecoration: "underline"}}>Day Of</RouterLink>
        </li>
        <li className="flex-grow px-4 text-center" key="faq">
          <RouterLink to="/faq" exact activeStyle={{fontWeight: 'bold', textDecoration: "underline"}}>FAQ</RouterLink>
        </li>
        <li className="flex-grow px-4 text-center" key="photos">
          <RouterLink to="/photos" exact activeStyle={{fontWeight: 'bold', textDecoration: "underline"}}>Photos</RouterLink>
        </li>
        <li className="flex-grow px-4 text-center" key="registry">
          <RouterLink to="/registry" exact activeStyle={{fontWeight: 'bold', textDecoration: "underline"}}>Registry</RouterLink>
        </li>
      </ul>
    </nav>
  );
};