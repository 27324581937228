import React/*, { useEffect, useState }*/ from "react";
import { MainHeroPage, Link } from '../components';

// const calculateTimeRemaining = () => {
//   const total = Date.parse('August 7, 2021 17:00:00') - Date.parse((new Date()).toString());
//   const seconds = Math.floor( (total/1000) % 60 );
//   const minutes = Math.floor( (total/1000/60) % 60 );
//   const hours = Math.floor( (total/(1000*60*60)) % 24 );
//   const days = Math.floor( total/(1000*60*60*24) );
//   return {
//     days,
//     hours,
//     minutes,
//     seconds,
//   }; //`${days} days, ${hours} hours, ${minutes} minutes, and ${seconds} seconds remaining`;
// };

// const CountdownClock = () => {
//   const [timeLeft, setTimeLeft] = useState(calculateTimeRemaining());
//   useEffect(() => {
//     const timer = setTimeout(() => {
//       setTimeLeft(calculateTimeRemaining());
//     }, 1000);
//     return () => clearTimeout(timer);
//   });
//   return (
//     <div className="text-6xl text-center flex w-full items-center justify-center pt-10">
//       <div className="w-24 mx-1 p-2 bg-white bg-opacity-50 text-gray-600 rounded-lg">
//         <div className="font-sans leading-none" x-text="days">{timeLeft.days}</div>
//         <div className="font-sans text-sm leading-none">Days</div>
//       </div>
//       <div className="w-24 mx-1 p-2 bg-white bg-opacity-50 text-gray-600 rounded-lg">
//         <div className="font-sans leading-none" x-text="hours">{timeLeft.hours}</div>
//         <div className="font-sans text-sm leading-none">Hours</div>
//       </div>
//       <div className="w-24 mx-1 p-2 bg-white bg-opacity-50 text-gray-600 rounded-lg">
//         <div className="font-sans leading-none" x-text="minutes">{timeLeft.minutes}</div>
//         <div className="font-sans text-sm leading-none">Minutes</div>
//       </div>
//       <div className="w-24 mx-1 p-2 bg-white bg-opacity-50 text-gray-600 rounded-lg">
//         <div className="font-sans leading-none" x-text="seconds">{timeLeft.seconds}</div>
//         <div className="font-sans text-sm leading-none">Seconds</div>
//       </div>
//     </div>
//   );
// }

export default function Main() {

  return (
    <MainHeroPage pageName="Main">
      <h1 className="font-bold text-6xl bg-clip-text text-transparent bg-gradient-to-r from-red-300 to-yellow-100">John Ward</h1>
      <h1 className="font-bold text-3xl bg-clip-text text-transparent bg-gradient-to-r from-yellow-200 to-red-200">&</h1>
      <h1 className="font-bold text-6xl bg-clip-text text-transparent bg-gradient-to-r from-yellow-100 to-red-300">Hannah Kim</h1>
      <br className="py-10" />
      <h1 className="text-white font-bold text-3xl pt-10">Saturday, August 7, 2021</h1>
      <br className="py-10" />
      <h3 className="text-white font-bold text-xl"><Link href="https://www.jameswardmansion.com/">The James Ward Mansion</Link></h3>
      <h3 className="text-white font-bold text-xl">Westfield, NJ</h3>
      {/* <CountdownClock /> */}
    </MainHeroPage>
  );
};