import React from 'react';

type LinkProps = {
  href?: string,
  disabled?: boolean,
  children: React.ReactNode,
};

export const DisabledLink = (props: LinkProps) => {
  return <Link {...props} disabled />;
};

export default function Link ({href, disabled, children}: LinkProps) {
  const classNames = [
    'transition',
    'duration-100',
    'ease-in-out',
    'opacity-100',
    'hover:opacity-80',
  ];
  if(disabled) {
    classNames.push('cursor-not-allowed');
    <a href="/#" className={classNames.join(' ')}>{children}</a>
  }
  return (
    <a href={href} className={classNames.join(' ')}>{children}</a>
  );
};