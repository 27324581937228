import React from "react";
import { Nav } from '.';

type PageProps = {
  pageName: string,
  children: React.ReactNode,
};

export default function Page(props: PageProps) {
  return (
    <div className="bg-cover bg-fixed bg-center flex flex-col h-screen overflow-hidden" style={{'backgroundImage': 'linear-gradient(rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4)), url(DR3A3014.jpeg)'}}>
      <Nav className="flex-initial"></Nav>
      <div className="container md m-auto text-center font-serif">{props.children}</div>
      {/* <Footer className="flex-initial">#hannahgetsaward</Footer> */}
    </div>
  );
}
