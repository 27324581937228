import React from 'react';
// @ts-ignore
import { Nav } from '.';
import { NavLink as RouterLink } from "react-router-dom";

type HeaderProps = {
  pageName: string,
  className?: string,
};

export default function Header ({pageName, className}: HeaderProps) {
  return (
    <div className={(className ?? '') + " bg-cover bg-right flex flex-col h-auto"} style={{'backgroundImage': 'linear-gradient(rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4)), url(IMG_1448_vert_centered.JPG)'}}>
      <h1 className="container md m-auto text-center bg-clip-text text-transparent bg-gradient-to-r from-red-200 to-yellow-100 font-bold text-6xl pt-8 font-serif"><RouterLink to="/" exact activeStyle={{fontWeight: 'bold', textDecoration: "underline"}}>John & Hannah</RouterLink></h1>
      <Nav className="flex-initial" />
    </div>
  );
};