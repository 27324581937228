import React from "react";
import { Page, Link } from '../components';
import {  Timeline  } from 'antd';


export default function DayOf() {
  return (
    <Page pageName="Day Of">
      <div className="mx-auto prose">
        <h2>Getting there</h2>
        <div className="container">
          <iframe className="h-full w-full" title="parking map" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d193546.01491716623!2d-74.31074776165009!3d40.714571568999546!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x89c3b1a8ea3cd959%3A0xab850c42f431dc33!2sJames%20Ward%20Mansion!5e0!3m2!1sen!2sus!4v1628036179193!5m2!1sen!2sus" width="600" height="450" style={{border:0}} loading="lazy"></iframe>
        </div>
        <h4>Driving &amp; Parking</h4>
        <p>There is a <Link href="https://goo.gl/maps/9BLTkYy4m8bFa67SA">municipal parking lot</Link> behind the Mansion, in front of the Trader Joe's. Parking is 50 cents per hour until 6PM, after which it is free.</p>
        <h4>Taking the train</h4>
        <p>The Westfield train station is a <Link href="https://goo.gl/maps/yQsmRH1nYWMoXfgG8">4 minute walk</Link> from the Mansion and has <Link href="https://goo.gl/maps/HNfGcQ29WvrwFT639">connections to New York Penn Station</Link> if you are traveling from the city.</p>
        <h2>Schedule</h2>
      </div>
      <Timeline mode={"left"} className="pt-8">
        <Timeline.Item label="4:30pm">Guests arrive</Timeline.Item>
        <Timeline.Item label="5:00pm">Ceremony</Timeline.Item>
        <Timeline.Item label="5:30pm">Cocktail hour</Timeline.Item>
        <Timeline.Item label="6:30pm">Toasts & First Dances</Timeline.Item>
        <Timeline.Item label="7:00pm">Food & Dancing</Timeline.Item>
        <Timeline.Item label="9:00pm">Cake Cutting</Timeline.Item>
        <Timeline.Item label="10:00pm">End</Timeline.Item>
      </Timeline>
      <div className="mx-auto prose">
        <h2>Menus</h2>
        <p>Servers will take your main course selection at the table. Chicken and salmon are available, as well as a vegetarian option.</p>
      </div>
    </Page>
  );
};