import React from "react";
import { Page, Link } from '../components';

export default function FAQ() {
  return (
    <Page pageName="FAQ">
      <div className="prose mx-auto">
        <h2>Where do I park?</h2>
        <p>There is a parking lot directly behind the Mansion. Please see the "<Link href="/day-of">Day Of</Link>" page for directions.</p>
        <h2>What time should I get there?</h2>
        <p>Guests should plan to arrive at 4:30pm. The ceremony will be starting at 5pm. See the full schedule on the "<Link href="/day-of">Day Of</Link>" page.</p>
        <h2>Is there a preferred hotel?</h2>
        <p>Guests are encouraged to book their stay at the <Link href="https://www.hotelwestfield.com/">Best Western in Westfield</Link>. It is a 3 minute walk from the venue.</p>
        <h2>Can I bring a +1?</h2>
        <p>Only people who received their own RSVP link from us in February are invited. While we wish we could invite more people, please understand we had to make the celebration smaller and more intimate.</p>
        <h2>What is your policy on mask wearing?</h2>
        <p>Following the lead of other local venues, we are verifying guests' vaccination statuses prior to attending. With that in mind, you are welcome to wear yours if you feel more comfortable doing so, but we don't plan to require masks for guests.</p>
        <h2>What is the dress code?</h2>
        <p>Semi-formal or dressy casual. Colors are welcome - it is not a black-tie event. No swimsuits.</p>
      </div>
    </Page>
  );
};