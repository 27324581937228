import React from 'react';

type FooterProps = {
  className?: string,
  children: React.ReactNode,
};

export default function Footer({className, children}: FooterProps) {
  return (
    <footer className={[className ?? '', 'w-screen', 'mx-auto', 'text-center', 'bg-gray-900', 'text-white', 'leading-10 py-16', 'font-serif'].join(' ')}>
      {children}
    </footer>
  );
};